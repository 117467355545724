<template>
	<div class="bg_dark">
		<div class="container">
			<div class="row">
				<div class="pt-80">
					<div class="intro_logo_title">
						<span class="hide">{{ $language.common.title }}</span>
					</div>
				</div>
				<div class="intro_btn_wrap">
					<div class="social_login">

						<button
							class="btn_l btn_kakao"
							@click="joinWith('kakao')"
						>Kakao {{ $language.common.signUp }}</button>

						<button
							class="btn_l btn_google"
							@click="joinWith('google')"
							:disabled="!isInit"
						>Google {{ $language.common.signUp }}</button>

						<button
							class="btn_l btn_apple"
							@click="joinWith('apple')"
						>Apple {{ $language.common.signUp }}</button>

						<vue-apple-login
							v-show="false"

							:onSuccess="handleAppleLoginSuccess"
							:onFailure="handleAppleLoginFail"
						></vue-apple-login>
					</div>


					<div
						v-if="false"
					>
					<button
						type="primary"
						icon="fas fa-edit"
						@click="handleClickLogin"
						:disabled="!isInit"
					>get authCode</button>
					<button
						type="primary"
						icon="fas fa-edit"
						@click="handleClickSignIn"
						v-if="!isSignIn"
						:disabled="!isInit"
					>sign in</button>
					<button
						type="primary"
						icon="fas fa-edit"
						@click="handleClickSignOut"
						v-if="isSignIn"
						:disabled="!isInit"
					>sign out</button>
					<button
						type="primary"
						icon="fas fa-edit"
						@click="handleClickDisconnect"
						:disabled="!isInit"
					>disconnect</button>
					<i class="fas fa-edit"></i>
					<p>isInit: {{isInit}}</p>
					<p>isSignIn: {{isSignIn}}</p>

					<button
						type="primary"
						icon="fas fa-edit"
						@click="handleClickUpdateScope"
						:disabled="!isInit"
					>update scope</button>
					</div>

					<a @click="$emit('to', { name: 'login'})" class="btn_signup">{{ $language.common.login }}</a>
					<p class="copyright">Copyright © MAFI Inc. </p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'mafia005'
	,data: function(){
		return {
			program: {
				name: this.$language.common.signUp
				, not_header: true
				, not_footer: true
			}
			, kakao_info: null
			, isInit: false
			, isSignIn: false
			, kakao_token: null
			, social_code: ''
			, state: '1234'
		}
	}
	, computed: {
		kakao_return_url: function () {

			let domain = process.env.VUE_APP_DOMAIN
			let dev = process.env.VUE_APP_DEV
			let local = 'http://localhost:8080'
			let location = window.location.href
			let url = ''

			console.log('location', location)
			if (location.indexOf(domain) > -1) {
				url = domain
			} else if (location.indexOf(dev) > -1) {
				url = dev
			} else {
				url = local
			}

			return url + '/auth/join/kakao'
		}
	}
	,methods: {
		joinWith: async function(type){
			console.log('joinWith')
			if(type == 'kakao'){
				this.social_code = 'MB00400001'
				try{
					this.$common.inAppWebviewCommunity('android', 'sign_in_kakao')
				}catch(e){
					console.log('kakao login error', e)
					await this.handleSignInKakao();
				}

			}else if(type == 'google'){
				this.social_code = 'MB00400003'
				try{
					this.$common.inAppWebviewCommunity('android', 'sign_in_google')
				}catch(e){
					console.log('google login error', e)
					await this.handleClickUpdateScope2()
				}

			}else if(type == 'apple'){
				this.social_code = 'MB00400002'
				// next
				try{
					this.$common.inAppWebviewCommunity('android', 'sign_in_apple')
				}catch(e){
					document.getElementById('appleid-signin').click()

					console.log('google login error', e)
				}
			}

			window.sign_in_social = (e) => {
				console.log('window.sign_in_social', e)
				this.joinWithSocial(e)
			}
		}

		,kakaoLogin: async function(){
			let self = this
			window.Kakao.Auth.loginForm({
				success: function(authObj) {
					this.kakao_info = authObj
					console.log(this.kakao_info, authObj)
					console.log('kakao login token', window.Kakao.Auth.getAccessToken())

					self.joinWithSocial(authObj)
				},
				fail: function(err) {
					console.log(err)
				},
			})
		}
		,kakaoRequest: async function(){

			let self = this
			window.Kakao.API.request({
				url: '/v2/user/me',
				data: {
					property_keys: ["kakao_account.email"]
				},
				success: function(response) {
					self.kakao_info = response.kakao_account
					//self.postCheckEmail(self.kakao_info);
					console.log(self.kakao_info)
				},
				fail: function(error) {
					console.log(error);
				}
			});
		}
		,kakaoLogout: async function(){

			if (!window.Kakao.Auth.getAccessToken()) {
				console.log('Not logged in.')
				return false
			}else {
				window.Kakao.Auth.logout(function () {
					console.log('logout ok\naccess token -> ' + window.Kakao.Auth.getAccessToken())
					return true
				})
			}
		}
		,kakaoUnlink: async  function(){
			console.log('unlink')
			window.Kakao.API.request({
				url: '/v2/user/unlink',
				success: function(response) {
					console.log('success unlink')
					console.log(response);
					return true
				},
				fail: function(error) {
					console.log('error: ')
					console.log(error)
					return false
				},
			});
		}

		,onSignIn: function(googleUser) {
			// Useful data for your client-side scripts:
			var profile = googleUser.getBasicProfile();
			console.log("ID: " + profile.getId()); // Don't send this directly to your server!
			console.log('Full Name: ' + profile.getName());
			console.log('Given Name: ' + profile.getGivenName());
			console.log('Family Name: ' + profile.getFamilyName());
			console.log("Image URL: " + profile.getImageUrl());
			console.log("Email: " + profile.getEmail());

			// The ID token you need to pass to your backend:
			var id_token = googleUser.getAuthResponse().id_token;
			console.log("ID Token: " + id_token);
		}
		,joinWithKakao: async function(code, return_url){

			try{
				const result = await this.$sample.postJoinWithKakao(code, return_url)
				if(result.success){
					await this.$router.push({name: 'mafia0101'})
				}else{
					throw result.message
				}

			}catch(e){
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}
		}
		,async handleClickUpdateScope() {
			try{
				this.$common.inAppWebviewCommunity('android', 'sign_in_social')
			}catch (e) {
				console.log(e)
			}
		}
		,async handleClickUpdateScope2() {
			await this.$gAuth.signOut();
			const option = new window.gapi.auth2.SigninOptionsBuilder();
			option.setScope("email https://www.googleapis.com/auth/drive.file");

			const googleUser = await this.$gAuth.GoogleAuth.currentUser.get()
			let t = googleUser.getAuthResponse();
			console.log("t", t);

			try {
				let result = await googleUser.grant(option);
				console.log("result", result);

				if(!t.access_token){
					t = googleUser.getAuthResponse();
					console.log("t re!!", t);
				}

				let join_info = localStorage.getItem('join_info')

				join_info = JSON.parse(join_info)
				join_info.join_type = 'google'
				join_info.access_token = t.access_token

				//await this.postCheckEmail(join_info);

				await this.joinWithSocial(t)
			} catch (error) {
				console.error(error);
			}
		},
		postCheckEmail: async function(join_info){
			try{
				let result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_check_email
					, data: {
						email: join_info.email
						, access_token: join_info.access_token
						, join_type: join_info.join_type
					}
					, name: 'postCheckEmail'
				})
				if(result.success){

					localStorage.setItem('join_info', JSON.stringify(join_info))
					this.$emit('to', { name: 'nice'})
				}else{
					throw result.message
				}
			}catch (e){
				console.log(e)
				this.$emit('to', { name: 'mafia009', params: { type: e}})
				//this.$bus.$emit('notify', { type: 'error', message: e})
			}
		},
		handleClickLogin() {
			this.$gAuth.getAuthCode().then((authCode) => {
					//on success
					console.log("authCode", authCode);
				}).catch((error) => {
					console.log(error)
				});
		},
		async handleClickSignIn() {
			try {
				const googleUser = await this.$gAuth.signIn();
				if (!googleUser) {
					return null;
				}
				console.log("googleUser", googleUser);
				console.log("getId", googleUser.getId());
				console.log("getBasicProfile", googleUser.getBasicProfile());
				console.log("getAuthResponse", googleUser.getAuthResponse());
				console.log(
					"getAuthResponse",
					this.$gAuth.GoogleAuth.currentUser.get().getAuthResponse()
				);
				this.isSignIn = this.$gAuth.isAuthorized;
			} catch (error) {
				//on fail do something
				console.error(error);
				return null;
			}
		},
		async handleClickSignOut() {
			try {
				await this.$gAuth.signOut();
				this.isSignIn = this.$gAuth.isAuthorized;
				console.log("isSignIn", this.$gAuth.isAuthorized);
			} catch (error) {
				console.error(error);
			}
		}
		, handleClickDisconnect() {
			window.location.href = `https://www.google.com/accounts/Logout?continue=https://appengine.google.com/_ah/logout?continue=${window.location.href}`;
		}
		, async handleSignInKakao() {
			if(!window.Kakao.isInitialized()){
				window.Kakao.init(process.env.VUE_APP_KEY_KAKAO_JAVASCIRPT)
			}

			let t = false
			if(t){
				let REST_API_KEY = process.env.VUE_APP_KEY_KAKAO_REST
				let REDIRECT_URI = this.kakao_return_url
				window.document.location.href = `https://kauth.kakao.com/oauth/authorize?response_type=code&client_id=${REST_API_KEY}&redirect_uri=${REDIRECT_URI}&state=1234`
			}else{
				console.log(window.Kakao.isInitialized());
				console.log('kakao token: ' + window.Kakao.Auth.getAccessToken())
				await this.kakaoLogin()
			}
		}

		, joinWithSocial: async function(e){

			let join_info = localStorage.getItem('join_info')

			join_info = JSON.parse(join_info)
			join_info.type = e.type
			join_info.email = e.email
			join_info.access_token = e.access_token

			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_join_social
					, data: {
						member_social_certfc_code: this.social_code
						, social_certfc_code: e.access_token
						, agree_stip_list:[
							{ stip_number: join_info.terms_version }
							, { stip_number: join_info.personal_version}
						]
					}
					, type: true
				})

				if(result.success){
					this.token_info = result.data
					// console.log('this.token_info', this.token_info)

					let certfc = this.$encodeStorage.getSessionCertfc()
					if(!certfc){
						throw 'not certfc'
					}else{
						if(result.data.session_token){
							await this.$encodeStorage.setSessionToken(result.data.session_token)
						}
					}

					switch (result.data.member_state_code){
						case 'MB00500001': default:
							this.$emit('to', {name: 'sms'})
							break;
						case 'MB00500002':
							this.$bus.$emit('onPin', 'join')
							break;
						case 'MB00500003':
							this.$emit('to', {name: 'mafia0101'})
							break;
						case 'MB00500004':
							this.$emit('to', {name: 'nice'})
							break;
						case 'MB00500005':
							this.$bus.$emit('to', { name: 'mafia009', params: {type: 'already', msg: result.message}})
							break;
						case 'MB00500006':
							this.$bus.$emit('to', { name: 'mafia009', params: {type: 'stop', msg: result.message}})
							break;
						case 'MB00500007':
							this.$emit('to', {name: 'mafia0101'})
							break;
						case 'MB00500008':
							this.$bus.$emit('to', { name: 'mafia009', params: {type: 'sleep', msg: result.message}})
							break;
					}
				}else{
					switch (result.code){
						case 'E000400012':
							this.$bus.$emit('to', { name: 'mafia009', params: {type: 'stop', msg: result.message}})
							break;
						case 'E000400013':
							this.$bus.$emit('to', { name: 'mafia009', params: {type: 'out', msg: result.message}})
							break;
						case 'E000400014':
							this.$bus.$emit('to', { name: 'mafia009', params: {type: 'sleep', msg: result.message}})
							break;
						case 'E000400045':
							this.$bus.$emit('to', { name: 'mafia009', params: {type: 'already', msg: result.message}})
							break
						default:
							throw result.message
					}
				}
			}catch (e) {
				console.log(e)
				this.error = e
				this.$emit('to', { name: 'mafia009', params: { type: 'third', msg: e}})
			}finally {
				this.$bus.$emit('on', false)
				/*
				if(process.env.VUE_APP_TYPE == 'sample'){
					const result = await this.$sample.postJoinWithKakao(this.code, this.kakao_return_url)
					console.log('postJoinWithKakao result', result)
					if(result.success){
						this.$emit('to', { name: 'mafia0101'})
					}else{
						this.$emit('to', { name: 'mafia009', params: { type: 'third', msg: result.message}})
					}
				}

				 */
			}
		}
		, handleAppleLoginSuccess: function(data){
			this.social_code = 'MB00400002'
			let e = {
				access_token: data.authorization.code
			}
			this.joinWithSocial(e)
		}
		, handleAppleLoginFail: function(error){
			console.log(error)
			this.$bus.$emit('notify', { type: 'error', message: this.$language.auth.login_with_apple_fail })
		}

	}
	,created() {
		this.$emit('onLoad', this.program)

		let self = this;
		let checkGauthLoad = setInterval(function () {
			self.isInit = self.$gAuth.isInit;
			self.isSignIn = self.$gAuth.isAuthorized;
			if (self.isInit) clearInterval(checkGauthLoad);
		}, 1000);

		this.$bus.$on('pinCallback', (pin_type) => {
			switch (pin_type){
				case 'login':

					break
				case 'update':
					break
				case 'join':

					this.$bus.$off('pinCallback')
					this.$emit('to', { name: 'mafia010'})
					break
			}
		})
	}
	,watch: {

	}
}
</script>